import { render, staticRenderFns } from "./oridepman.vue?vue&type=template&id=46015a23&scoped=true&"
import script from "./oridepman.vue?vue&type=script&lang=js&"
export * from "./oridepman.vue?vue&type=script&lang=js&"
import style0 from "./oridepman.vue?vue&type=style&index=0&id=46015a23&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46015a23",
  null
  
)

export default component.exports