<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">科室管理</h2>
                <div class="RightDiv">
                    <el-form :model="searchForm" class="searForm">
                        <div class="table-title">
                            <div>
                                <el-input v-model="searchForm.nameLike" placeholder="科室名称" clearable prefix-icon="el-icon-search" @keyup.enter.native="searchList" @blur="searchList" @clear="searchList">
                                </el-input>
                            </div>
                            <div class="index-add">
                                <el-button type="primary" @click="depaddKeshi">添加科室</el-button>
                            </div>
                        </div>
                    </el-form>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="科室名称" align="center">
                    </el-table-column>
                    <el-table-column prop="type" label="科室类型" width="100" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.type=='0'">
                                临床医疗
                            </div>
                            <div v-else-if="scope.row.type=='1'">
                                医疗技术
                            </div>
                            <div v-else-if="scope.row.type=='2'">
                                医疗辅助
                            </div>
                            <div v-else-if="scope.row.type=='3'">
                                行政后勤
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" width="200" align="center">
                        <template slot-scope="scope">
                            <div class="remakSty">
                                {{scope.row.remark}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="updateDate" label="更新时间" align="center" width="160">
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="200">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="setKeshi(scope.row)"><i class="el-icon-plus"></i></el-button>
                            <el-button size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>
                            <el-button size="mini" type="danger" @click="deleteKeshi(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击添加科室弹出框 -->
        <el-dialog :title="addName" :visible.sync="AdddialogVisible" width="360px" :before-close="AdddialogVisibleClose">
            <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="100px">
                <el-form-item label="科室名称：" prop="name">
                    <el-input v-model="AddDateModel.name" placeholder="请填写科室名称"></el-input>
                </el-form-item>

                <el-form-item label="科室类型：" prop="type">
                    <el-select v-model="AddDateModel.type" placeholder="请选择科室类型" @input="change($event)" @change="typeChange(AddDateModel.type)">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="备注：" prop="text">
                    <el-input @input="change($event)"   maxlength="250" show-word-limit type="textarea" v-model="AddDateModel.text" style="width:70%"></el-input>
                </el-form-item>

                <el-form-item class="depar-dia btnCen">
                    <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="parmen">{{btnText}}</el-button>
                    <el-button v-else type="info" :loading="addDepClickKing"></el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 点击科室用户管理 -->
        <el-dialog title="科室病区管理" :visible.sync="SetdialogVisible" width="80%" :before-close="SetdialogVisibleClose">
            <Medire-info :hosT='host' :depId='depId' />
        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
import MedireInfo from "../MedireInfo/MedireInfo.vue";
export default {
    data() {
        var nameSpace = async (rule, value, callback) => {
            if (!value) {
                callback(new Error("请输入科室名称"));
            } else if (value) {
                // console.log(this.addName);
                if (this.addName == "修改科室") {
                    if (this.reayName == value) {
                        callback();
                    } else {
                        let data = _qs.stringify({
                            name: value,
                        });
                        let res = await this.$axios.judgeDepartment(data);
                        // console.log(res);
                        if (res.data.code == 200) {
                            callback();
                        } else {
                            callback(new Error(res.data.msg));
                        }
                    }
                } else {
                    let data = _qs.stringify({
                        name: value,
                    });
                    let res = await this.$axios.judgeDepartment(data);
                    // console.log(res);
                    if (res.data.code == 200) {
                        callback();
                    } else {
                        callback(new Error(res.data.msg));
                    }
                }
            } else {
                callback();
            }
        };
        return {
            depId: "",
            host: "医院",
            btnText: "添加",
            addName: "添加科室",
            reayName: "",
            options: [
                {
                    value: "0",
                    label: "临床医疗",
                },
                {
                    value: "1",
                    label: "医疗技术",
                },
                {
                    value: "2",
                    label: "医疗辅助",
                },
                {
                    value: "3",
                    label: "行政后勤",
                },
            ],
            // 添加科室的添加按钮
            addDepClick: true,
            addDepClickKing: false,
            // 查询用的业务名称
            searchForm: {
                nameLike: "",
            },
            // 添加科室
            AddDateModel: {
                // 科室名称
                name: "",
                type: "",
            },
            AddDateRules: {
                name: [
                    { required: true, validator: nameSpace, trigger: "blur" },
                    // {
                    //     required: true,
                    //     message: "请输入科室名称",
                    //     trigger: "blur",
                    // },
                ],
                type: [
                    {
                        required: true,
                        message: "请选择科室类型",
                        trigger: "change",
                    },
                ],
            },
            // 点击添加科室弹出框
            AdddialogVisible: false,
            // 修改科室弹出框
            EditdialogVisible: false,
            // 科室用户管理
            SetdialogVisible: false,
            // 科室管理的分页
            // 当前页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 总数
            total: 1,
            // 表格数据
            tableData: [],
            // 医院id
            hospitalId: window.sessionStorage.getItem("hospitalId"),
            // 科室用户点击修改的id
            userId: "",
            keshiid: "",
        };
    },
    created() {
        this.getDepartmentList();
    },
    components: {
        MedireInfo,
    },
    methods: {
        // 搜索框
        searchList() {
            this.pageNum = 1;
            this.getDepartmentList();
        },
        setKeshi(row) {
            this.depId = row.id;
            this.SetdialogVisible = true;
        },
        // 科室管理分页
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.getDepartmentList();
        },
        // 点击添加科室
        depaddKeshi() {
            this.AdddialogVisible = true;
        },
        // 点击添加科室的时候取消正则
        AdddialogVisibleClose() {
            this.addName = "添加科室";
            this.btnText = "添加";
            this.reayName = "";
            this.AdddialogVisible = false;
            this.$refs.AddDateRef.resetFields();
            this.AddDateModel = {
                // 科室名称
                name: "",
                type: "",
            };
        },
        parmen() {
            if (this.btnText == "添加") {
                this.addKeshi();
            } else if (this.btnText == "修改") {
                this.editdialogKeshi();
            }
        },
        typeChange(val) {
            // console.log(val);
            this.AddDateModel.type = val;
        },
        change(e) {
            this.$forceUpdate(e);
        },
        // 点击操作添加科室的添加
        addKeshi() {
            this.$refs.AddDateRef.validate((valid) => {
                if (valid) {
                    this.addDepClick = false;
                    this.addDepClickKing = true;
                    let data = _qs.stringify({
                        hospitalId: this.hospitalId,
                        name: this.AddDateModel.name,
                        type: this.AddDateModel.type, // 科室类型（0临床医疗、1医疗技术、2医疗辅助、3行政后勤）
                        remark: this.AddDateModel.text, //备注
                    });
                    this.addDepartment(data);
                }
            });
        },
        // 点击操作删除
        async deleteKeshi(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                ids: row.id,
            });
            let { data: res } = await this.$axios.removeDepartment(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.$message({
                    message: "删除成功",
                    type: "success",
                });
                this.getDepartmentList();
            } else {
                this.$message({
                    message: "科室中存在用户，不能删除。",
                    type: "error",
                });
            }
        },
        // 点击操作修改科室的添加
        editKeshi(row) {
            this.addName = "修改科室";
            this.btnText = "修改";
            this.keshiid = row.id;
            this.AddDateModel.name = row.name;
            this.reayName = row.name;
            this.AddDateModel.text = row.remark;
            this.AdddialogVisible = true;
            this.AddDateModel.type = row.type + "";
        },
        SetdialogVisibleClose() {
            this.SetdialogVisible = false;
            this.depId = "";
        },
        // 点击修改科室用户管理的添加
        editdialogKeshi() {
            this.$refs.AddDateRef.validate((valid) => {
                if (!valid) return;
                this.addDepClick = false;
                this.addDepClickKing = true;
                let data = _qs.stringify({
                    name: this.AddDateModel.name, //科室名称
                    hospitalId: this.hospitalId, //医院id
                    id: this.keshiid, //科室id
                    remark: this.AddDateModel.text, //备注
                    type: this.AddDateModel.type,
                });
                this.addDepartment(data);
            });
        },
        // 添加或修改科室信息接口
        async addDepartment(data) {
            let res = await this.$axios.addDepartment(data);
            // console.log(res);
            this.addDepClick = true;
            this.addDepClickKing = false;
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                this.AdddialogVisibleClose();
                // 添加科室
                this.AddDateModel = {
                    // 科室名称
                    name: "",
                };
                this.getDepartmentList();
                this.$message({
                    message: res.data.msg,
                    type: "success",
                });
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        // 分页显示科室管理数据
        async getDepartmentList() {
            let data = {
                hospitalId: this.hospitalId, //登录后返回的 医院id
                nameLike: this.searchForm.nameLike, //查询用的 科室名称
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示条数
                orderByColumn: "updateDate", //排序参数 updateDate 更新时间
                isAsc: "desc", //asc 升序 desc降序
            };
            let { data: res } = await this.$axios.getDepartmentList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            }
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
    text-align: inherit;
    .btnCen {
        button {
            width: 150px;
        }
    }
}

.RightDiv {
    margin: 0 auto;
}
.searForm {
    float: right;
}
.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}
.depart-addnum {
    width: 100%;
}

.depar-dia {
    width: 100%;
}
/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}
.el-main {
    .table-title {
        display: flex;
        margin-bottom: 10px;
        .index-add {
            margin-left: 20px;
        }
    }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

/deep/ .el-textarea__inner {
    width: 220px !important;
}

/deep/ .el-textarea {
    width: 100% !important;
}

/deep/ .el-input__inner {
    width: 220px !important;
}
</style>
