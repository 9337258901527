<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle" v-if="hosT!=='医院'">科室病区信息</h2>
                <div class="hosrow">
                    <div>
                        <el-input v-model="meName" placeholder="病区名称" clearable prefix-icon="el-icon-search" @keyup.enter.native="searchList" @blur="searchList" @clear="searchList">
                        </el-input>
                    </div>
                    <div class="addBtn">
                        <el-button type="primary" @click="depaddKeshi">新增病区</el-button>
                    </div>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="病区名称" align="center">
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" align="center" width="200px">
                        <template slot-scope="scope">
                            <div class="remakSty">{{scope.row.remark}}</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
            prop="deleteFlag"
            label="状态"
            align="center"
          >
            <template slot-scope="scope">
              <div
                class="activeStatus"
                v-if="scope.row.deleteFlag == 1"
              >√</div>
              <div
                class="noStatus"
                v-else
              >√</div>
            </template>
          </el-table-column> -->
                    <el-table-column label="操作" align="center" width="250">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>
                            <el-button size="mini" type="danger" @click="deleteKeshi(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击新增病区信息弹出框 -->
        <el-dialog :title="meTit" :visible.sync="AdddialogVisible" width="360px" :before-close="AdddialogVisibleClose" append-to-body>
            <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="100px">
                <el-form-item label="病区名称：" prop="name">
                    <el-input v-model="AddDateModel.name" placeholder="请填写病区名称"></el-input>
                </el-form-item>
                <el-form-item label="备注：" prop="remark">
                    <el-input type="textarea"   maxlength="250" placeholder="请填写备注" show-word-limit v-model="AddDateModel.remark"></el-input>
                </el-form-item>
                <div class="btnCen">
                    <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="inward">{{btnNam}}</el-button>
                      <el-button v-else type="info" :loading="addDepClickKing"></el-button>
                </div>

            </el-form>
        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
export default {
    props: ["hosT", "depId"],
    data() {
        var nameSpace = async (rule, value, callback) => {
            if (!value) {
                callback(new Error("请输入病区名称"));
            } else if (value) {
                // console.log(this.meTit);
                // console.log(this.hosT)
                let depId;
                if (this.hosT == "医院") {
                    depId = this.depId;
                } else {
                    depId = window.sessionStorage.getItem("departmentId");
                }
                if (this.meTit == "修改病区信息") {
                    if (this.reayName == value) {
                        callback();
                    } else {
                        let data = _qs.stringify({
                            name: value,
                            departmentId: depId, //病区所属科室id
                        });
                        let res = await this.$axios.judgeInpatientWard(data);
                        // console.log(res);
                        if (res.data.code == 200) {
                            callback();
                        } else {
                            callback(new Error(res.data.msg));
                        }
                    }
                } else {
                    let data = _qs.stringify({
                        name: value,
                        departmentId: depId, //病区所属科室id
                    });
                    let res = await this.$axios.judgeInpatientWard(data);
                    // console.log(res);
                    if (res.data.code == 200) {
                        callback();
                    } else {
                        callback(new Error(res.data.msg));
                    }
                }
            } else {
                callback();
            }
        };
        return {
            reayName: "",
            btnNam: "新增",
            meTit: "新增病区信息",
            meName: "", //病历名称
            // 添加科室的添加按钮
            addDepClick: true,
            addDepClickKing: false,
            // 添加科室
            AddDateModel: {
                // 科室名称
                name: "",
                remark: "",
            },
            AddDateRules: {
                name: [
                    { required: true, validator: nameSpace, trigger: "blur" },
                    // {
                    //     required: true,
                    //     message: "请输入病区名称",
                    //     trigger: "blur",
                    // },
                ],
                remark: [
                    {
                        required: true,
                        message: "备注不能为空",
                        trigger: "blur",
                    },
                ],
            },
            // 点击添加科室弹出框
            AdddialogVisible: false,
            // 科室管理的分页
            // 当前页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 总数
            total: 1,
            // 表格数据
            tableData: [],
            wardId: "", //病区id
        };
    },
    watch: {
        depId: {
            handler(newVal, oldVal) {
                if (window.sessionStorage.getItem("type") == "1") {
                    this.departmentId =
                        window.sessionStorage.getItem("departmentId");
                } else if (window.sessionStorage.getItem("type") == "0") {
                    this.departmentId = newVal;
                }
                this.listhosInward();
            },
            deep: true,
        },
    },
    created() {
        // 0 医院用户   1 科室用户
        if (window.sessionStorage.getItem("type") == "1") {
            this.departmentId = window.sessionStorage.getItem("departmentId");
        } else if (window.sessionStorage.getItem("type") == "0") {
            this.departmentId = this.depId;
        }
        this.listhosInward();
    },
    methods: {
        // 病历名称
        searchList() {
            this.pageNum = 1;
            this.listhosInward();
        },
        // 科室管理分页
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.listhosInward();
        },
        // 点击添加科室
        depaddKeshi() {
            this.AdddialogVisible = true;
        },
        // 点击添加科室的时候取消正则
        AdddialogVisibleClose() {
            this.meTit = "新增病区信息";
            this.btnNam = "新增";
            this.reayName = "";
            this.AdddialogVisible = false;
            this.AddDateModel = {
                // 科室名称
                name: "",
                remark: "",
            };
            this.$refs.AddDateRef.resetFields();
        },
        inward() {
            if (this.btnNam == "新增") {
                this.addKeshi();
            } else if (this.btnNam == "修改") {
                this.editdialogKeshi();
            }
        },
        // 点击操作添加科室的添加
        addKeshi() {
            this.$refs.AddDateRef.validate(async (valid) => {
                if (valid) {
                    this.addDepClick = false;
                    this.addDepClickKing = true;
                    let data = _qs.stringify({
                        departmentId: this.departmentId, //所属科室id
                        name: this.AddDateModel.name, //病区名称
                        remark: this.AddDateModel.remark, //	备注
                    });
                    let res = await this.$axios.addInward(data);
                    // console.log(res);
                    this.addDepClick = true;
                    this.addDepClickKing = false;
                    if (res.data.code == 401) {
                        this.$router.push("/login");
                    } else if (res.status == 200) {
                        // 添加科室
                        this.AddDateModel = {
                            // 科室名称
                            name: "",
                            remark: "",
                        };
                        this.listhosInward();
                        this.AdddialogVisibleClose();
                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: "error",
                        });
                    }
                }
            });
        },
        // 点击操作删除
        async deleteKeshi(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                id: row.id,
            });
            let res = await this.$axios.deleteTnward(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 200) {
                this.listhosInward();
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        // 点击操作修改科室的添加
        editKeshi(row) {
            this.meTit = "修改病区信息";
            this.btnNam = "修改";
            this.wardId = row.id;
            this.AddDateModel.name = row.name;
            this.reayName = row.name;
            this.AddDateModel.remark = row.remark;
            this.AdddialogVisible = true;
        },
        // 点击修改科室用户管理的添加
        editdialogKeshi() {
            this.$refs.AddDateRef.validate(async (valid) => {
                if (!valid) return;
                this.addDepClick = false;
                this.addDepClickKing = true;
                let data = _qs.stringify({
                    name: this.AddDateModel.name, //病区名称
                    remark: this.AddDateModel.remark, //备注
                    id: this.wardId, //病区id
                });
                let res = await this.$axios.editTnward(data);
                // console.log(res);
                this.addDepClick = true;
                this.addDepClickKing = false;
                if (res.data.code == 401) {
                    this.$router.push("/login");
                } else if (res.status == 200) {
                    // 添加科室
                    this.AddDateModel = {
                        // 科室名称
                        name: "",
                        remark: "",
                    };
                    this.listhosInward();
                    this.AdddialogVisibleClose();
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: "error",
                    });
                }
            });
        },
        // 分页显示病区列表数据
        async listhosInward() {
            let data;
            if (this.hosT == "医院") {
                data = {
                    pageNum: this.pageNum, //页数
                    pageSize: this.pageSize, //每页显示条数
                    deleteFlag: "0", //数据状态（0正常 1已删除）
                    name: this.meName, //病区名称
                    departmentId: this.depId,
                };
            } else {
                data = {
                    pageNum: this.pageNum, //页数
                    pageSize: this.pageSize, //每页显示条数
                    deleteFlag: "0", //数据状态（0正常 1已删除）
                    name: this.meName, //病区名称
                };
            }
            let { data: res } = await this.$axios.listhosInward(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.RightDiv {
    margin: 0 auto;
}

.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}
.depart-addnum {
    width: 100%;
}

.AddUser {
    float: right;
    margin-bottom: 10px;
}

.AddUserDiv {
    width: 95%;
    margin: 0 auto;
}

.depar-dia {
    width: 100%;
}
.collapse-btn {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    background: rgb(134 140 146);
}
/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}
.el-main {
    .table-title {
        display: flex;
        margin-bottom: 10px;
        .index-add {
            margin-left: 20px;
        }
    }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

.hosrow {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.addBtn {
    margin-left: 10px;
}

.activeStatus {
    background: rgb(102, 204, 0);
}

.noStatus {
    background: #ccc;
}

.noStatus,
.activeStatus {
    width: 25px;
    height: 25px;
    color: white;
    line-height: 25px;
    text-align: center;
    margin: 0 auto;
}

/deep/ .el-textarea__inner {
    width: 220px !important;
}

/deep/ .el-form-item__content,
/deep/ .el-input__inner {
    width: 220px !important;
}

.btnCen {
    button {
        width: 150px;
    }
}
</style>
